<template>
  <div>
    <b-card>
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="Update()"
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-xl-6">
            <div class="border rounded p-2 mb-2">
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    :src="popUpimage"
                    height="300"
                    width="300"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <div>
                  <b-media-body>
                    <b-form-group label="Video Image" label-for="about_image">
                      <b-form-file
                        id="about_image"
                        accept=".jpg, .png, .gif"
                        @change="loadImage($event)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                    <!--email-->
                  </b-media-body>
                  <div>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="deleteImage"
                    >
                      Delete Image
                    </button>
                  </div>
                </div>
              </b-media>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="PopUp Title">
                  <Input
                    type="text"
                    v-model="form.title"
                    placeholder="Enter Popup Title"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Button Name">
                  <Input
                    type="text"
                    v-model="form.button_name"
                    placeholder="Enter Button Name"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Link">
                  <Input
                    type="text"
                    v-model="form.link"
                    placeholder="Enter Link"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Expiry Date">
                  <DatePicker
                    type="date"
                    v-model="form.endtime"
                    placeholder="Select date"
                    style="width: 100%"
                  ></DatePicker>
                </b-form-group>
              </div>
              <div class="col-xl-12">
                <b-form-group label="About Description" label-for="description">
                  <Input
                    type="textarea"
                    id="description"
                    v-model="form.description"
                    name="description"
                    style="margin-top: -20px"
                    placeholder="Enter About Description"
                    :autosize="{ minRows: 9, maxRows: 8 }"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <!-- submit buttons -->
        <b-button type="submit" variant="primary" :disabled="form.busy">
          Update
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import {
  BFormFile,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BFormDatepicker,
  BForm,
  BButton,
} from "bootstrap-vue";
import { formatDate } from "@/@core/utils/filter";
export default {
  data() {
    return {
      form: {
        title: null,
        description: null,
        button_name: null,
        link: null,
        image: null,
        endtime: null,
        busy: false,
      },
      popUpimage: null,
    };
  },

  mounted() {
    this.getResult();
  },

  methods: {
    loadImage(e) {
      this.form.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.popUpimage = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    async getResult() {
      const popUp = await this.callApi("get", "/app/cms/popUp");
      if (popUp) {
        this.form.title = popUp.data.popUpBanner.title || null;
        this.form.description = popUp.data.popUpBanner.description || null;
        this.form.button_name = popUp.data.popUpBanner.button_name || null;
        this.form.endtime = popUp.data.popUpBanner.endtime || null;
        this.form.link = popUp.data.popUpBanner.link || null;
        this.popUpimage =
          popUp.data.popUpBanner.image != null
            ? this.$store.state.base_url + popUp.data.popUpBanner.image
            : this.getImage(300, 300);
      }
    },
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    deleteImage() {
      axios
        .delete("/app/cms/popUp/delete")
        .then((res) => {
          this.getResult();
          this.s(res.data.message);
        })
        .catch((error) => {
          this.form.busy = false;
          this.errors = error.res.data.errors;
        });
    },
    Update() {
      const endTime = formatDate(this.form.endtime);
      const fd = new FormData();
      fd.append("title", this.form.title || "");
      fd.append("button_name", this.form.button_name || "");
      fd.append("link", this.form.link || "");
      fd.append("endtime", endTime);
      fd.append("description", this.form.description || "");
      fd.append("image", this.form.image ? this.form.image : null);

      axios
        .post("/app/cms/popUp/update", fd)
        .then((res) => {
          this.getResult();
          this.s(res.data.message);
        })
        .catch((error) => {
          this.form.busy = false;
          this.errors = error.res.data.errors;
        });
    },
  },
  components: {
    BFormFile,
    BMedia,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BFormDatepicker,
    BMediaBody,
  },
};
</script>
